import { AppBarNotice } from "@/models/entities/app-bar-notice.interface";
import { BillingAddress } from "@/models/entities/billing-address.interface";
import { ChangePassword } from "@/models/entities/change-password";
import { PasswordReset } from "@/models/entities/password-reset.interface";
import { PersonalDataChange } from "@/models/entities/personal-data-change.interface";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { User } from "@/models/entities/user.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "user";

class UserService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetPersonalData() {
    return this.getAll<PersonalDataChange>(`${controller}/getpersonaldata`);
  }

  UpdatePersonalData(personalDataChange: PersonalDataChange) {
    return this.post<PersonalDataChange, void>(
      `${controller}/updatepersonaldata`,
      personalDataChange
    );
  }

  CheckPassword(oldPassword: string) {
    return this.getAll<boolean>(
      `${controller}/checkpassword?oldPassword=${oldPassword}`
    );
  }

  ChangePassword(password: ChangePassword) {
    return this.post<ChangePassword, void>(
      `${controller}/changepassword`,
      password
    );
  }

  SendResetPswLink() {
    return this.getAll<void>(`${controller}/sendresetpswlink`);
  }

  CheckResetPswToken(token: string) {
    return this.getAll<boolean>(`${controller}/checkresetpswtoken`, {
      params: { token },
    });
  }

  ResetPassword(passwordReset: PasswordReset) {
    return this.post<PasswordReset, void>(
      `${controller}/resetpassword`,
      passwordReset
    );
  }

  GetSubscription() {
    return this.getAll<PromotionPlans>(`${controller}/getsubscription`);
  }

  SubscriptionDuration() {
    return this.getAll<number>(`${controller}/subscriptionduration`);
  }

  ChangeAccountStatusByID(status: boolean) {
    return this.post<number, void>(
      `${controller}/changeaccountstatusbyid/${status}`,
      1
    );
  }

  GetFuturePlan() {
    return this.getAll<number>(`${controller}/getfutureplan`);
  }

  GetBillingAddressByID() {
    return this.getAll<BillingAddress>(`${controller}/getbillingaddressbyid`);
  }

  GetBillingAddressAdmin(userID: number) {
    return this.getAll<BillingAddress>(
      `${controller}/getbillingaddressadmin/${userID}`
    );
  }

  UpdateBillingAddress(billingAddress: BillingAddress) {
    return this.post<BillingAddress, void>(
      `${controller}/updatebillingaddress`,
      billingAddress
    );
  }

  AdminUpdateBillingAddress(billingAddress: BillingAddress, userID: number) {
    return this.post<BillingAddress, void>(
      `${controller}/updatebillingaddress/${userID}`,
      billingAddress
    );
  }

  GetLogicallyDeletedById() {
    return this.getAll<boolean>(`${controller}/getlogicallydeletedbyid`);
  }

  PhysicalDeleteByID() {
    return this.post<number, void>(`${controller}/physicaldeletebyid`, 1);
  }

  GetConsumptionLocked() {
    return this.getAll<boolean>(`${controller}/getconsumptionlocked`);
  }

  GetAllUsers(profileRoleID: number, loadOnlyEnabled: boolean) {
    return this.getAll<User[]>(`${controller}/getAllUsers/${profileRoleID}`, {
      params: { loadOnlyEnabled },
    });
  }

  GetUserByID(userID: number) {
    return this.getAll<User>(`${controller}/getByID/${userID}`);
  }

  UpdateUser(user: User) {
    return this.post<User, void>(`${controller}/UpdateUser`, user);
  }

  GetAppBarNotice(locale: string) {
    return this.getAll<AppBarNotice>(`${controller}/appBarNotice`, {
      params: {
        locale: locale,
      },
    });
  }

  GetShouldShowTutorial() {
    return this.getAll<boolean>(`${controller}/shouldShowTutorial`);
  }
}

export const userService = new UserService();
