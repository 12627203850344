import { SubjectAndRole } from "@/models/entities/subject-role.interface";
import { NextPlan } from "@/models/entities/next-plan.interface";
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { OrderingSubject } from "@/models/entities/ordering-subject.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "orderingsubject";

class OrderingSubjectService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  ReadAll(UserId: number) {
    return this.getAll<OrderingSubjectView[]>(`${controller}/${UserId}`);
  }

  getAllById(orderingSubjectID: number) {
    return this.getAll<OrderingSubject>(
      `${controller}/getById?OrderingSubjectID=${orderingSubjectID}`
    );
  }

  AddOrderingSubjects(orderingSubject: OrderingSubject) {
    return this.post<OrderingSubject, number>(
      `${controller}/save`,
      orderingSubject
    );
  }

  DeleteOrderingSubjects(idOrderingSubject: number) {
    return this.getAll<void>(`${controller}/delete/${idOrderingSubject}`);
  }

  GetRoleAndTotSubject() {
    return this.getAll<SubjectAndRole>(`${controller}/getroleandtotsubject`);
  }

  GetNextPlan(oldTotalSubject: number) {
    return this.getAll<NextPlan>(
      `${controller}/getnextplan?oldTotalSubject=${oldTotalSubject}`
    );
  }
}

export const orderingSubjectService = new OrderingSubjectService();
