
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required } from "@/helpers/rules";
import { PasswordReset } from "@/models/entities/password-reset.interface";
import { SnackbarModule } from "@zelando/vuelando";
import { userService } from "@/services/api/user.service";

const snackbarModule = getModule(SnackbarModule, store);

const ModifyPassword = () =>
  import(
    /* webpackChunkName: "Password" */ "../Account/Components/Password.vue"
  );

@Component({
  components: {
    "modify-password": ModifyPassword,
  },
})
export default class ResetPasswordByLink extends Vue {
  private confirmNewPassword = "";
  private required = required;
  private success = false;
  private passwordReset: PasswordReset = {
    password: "",
    token: "",
  };
  private isFormValid = false;

  async created(): Promise<void> {
    this.passwordReset.token = this.$route.query.token as string;
    if (this.passwordReset.token) {
      var tokenIsValid = (
        await userService.CheckResetPswToken(this.passwordReset.token)
      ).data;
      if (tokenIsValid == false) {
        snackbarModule.showSnackbar({
          message: this.$tc("resetPassword.ExpiredToken"),
          type: "error",
          timer: 5000,
        });
        this.$router.push({ name: "home" });
      }
    }
  }

  private disabledSnackbar() {
    snackbarModule.showSnackbar({
      message: this.$tc("resetPassword.InsertData"),
      type: "error",
      timer: 5000,
    });
  }

  private async saveNewPassword() {
    if (this.isFormValid) {
      if (this.passwordReset.password === this.confirmNewPassword) {
        await userService.ResetPassword(this.passwordReset);
        this.success = true;
      } else {
        snackbarModule.showSnackbar({
          message: this.$tc("password.PasswordNotSame"),
          type: "error",
          timer: 5000,
        });
      }
    } else {
      snackbarModule.showSnackbar({
        message: this.$tc("password.InsertData"),
        type: "error",
        timer: 5000,
      });
    }
  }

  //   async created(): Promise<void> {
  //     let token = this.$route.query.token;

  //     if (token != null) {
  //       this.passwordChangeReset.token = token.toString();
  //     } else {
  //       this.$router.push({ path: "/login" });
  //     }
  //   }
  //   private async submitChange(): Promise<void> {
  //     overlayModule.showOverlay();
  //     this.passwordChangeReset.password = this.confirmNewPassword;
  //     await resetPasswordService.PasswordReset(this.passwordChangeReset);

  //     this.$router.push({ name: "login" });
  //   }
}
